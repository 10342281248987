<template>
  <b-row class=" mt-2">
    <b-col
      cols="3"
      class="d-flex align-items-center"
    >
      <span
        v-if="markedElements.length"
        class="text-muted"
      >{{ allElementsChecked ? 'All selected' : `Selected items(${markedElements.length})` }}</span>
    </b-col>
    <b-col
      cols="9"
      class="d-flex align-items-center justify-content-end"
    >
      <b-dropdown
        id="dropdown-buttons"
        text="Bulk Actions"
        variant="outline-danger"
      >
        <b-dropdown-item
          v-if="$can('add', permissionSubjects.GroupMember)"
          v-b-modal.bulk-add-to-group-modal
          :disabled="markedElements.length < 1"
        >
          Bulk Add To Group
        </b-dropdown-item>

        <b-dropdown-item
          v-if="canSendBulkCommunication"
          v-b-modal.bulk-communication-modal
          :disabled="markedElements.length < 1"
        >
          Bulk Communication
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$can('create', permissionSubjects.Charge)"
          v-b-modal.bulk-adding-charge-modal
          :disabled="markedElements.length < 1"
        >
          Bulk Charge
        </b-dropdown-item>
        <b-dropdown-item
          v-b-modal.bulk-generate-letter-modal
          :disabled="markedElements.length < 1"
        >
          Bulk Generate Letter
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('assign', permissionSubjects.Tags)"
          v-b-modal.bulk-tag-assign-modal
          :disabled="markedElements.length < 1"
        >
          Bulk Tag Assign
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('update', permissionSubjects.ChildApplicationStatus) && $can('update', permissionSubjects.StaffApplicationStatus)"
          v-b-modal.bulk-change-applications-status-modal
          :disabled="markedElements.length < 1"
        >
          Bulk Change Status
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('export', permissionSubjects.ExportPDF)"
          v-b-modal.export-pdf-modal
          :disabled="markedElements.length < 1"
        >
          <span>Export Statement</span>
        </b-dropdown-item>
      </b-dropdown>

      <b-button
        class="ml-1"
        variant="outline-primary"
        :disabled="totalItems == 0 || !canExportCSV"
        @click="$emit('exportReport')"
      >
        <span class="mr-25 align-middle">Export Report</span>
      </b-button>
      <b-button
        v-if="reportId"
        class="ml-1"
        variant="outline-primary"
        :disabled="!reportView || !canUpdateReport"
        @click="openSaveReportModal(true)"
      >
        <span class="mr-25 align-middle">Save As</span>
      </b-button>
      <b-button
        class="ml-1"
        variant="primary"
        :disabled="!reportView || !canUpdateReport"
        @click="openSaveReportModal(false)"
      >
        <span class="mr-25 align-middle">Save</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
} from 'bootstrap-vue'

import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  props: {
    markedElements: {
      type: Array,
      required: true,
    },
    allElementsChecked: {
      type: Boolean,
      required: true,
    },
    reportId: {
      type: [Number, String],
      required: true,
    },
    reportView: {
      type: Object,
      default: null,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  computed: {
    canUpdateReport() {
      return this.$can('update', permissionSubjects.Report)
    },
    canExportCSV() {
      return this.$can('export', permissionSubjects.ExportCSV)
    },
    canSendBulkEmailCommunication() {
      return this.$can('send', permissionSubjects.BulkEmailCommunication)
    },
    canSendBulkSmsCommunication() {
      return this.$can('send', permissionSubjects.BulkSMSCommunication)
    },
    canSendBulkCommunication() {
      return this.canSendBulkEmailCommunication || this.canSendBulkSmsCommunication
    },
  },
  methods: {
    openSaveReportModal(isSaveAs) {
      this.$emit('openSaveReportModal', isSaveAs)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
